import React from 'react'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import styles from './publicnotice.module.scss'

const PublicNoticePage = ({ pageContext: { locale, hrefLangs }, data }) => {
  const publicNotice = data.allContentfulPublicNotice.edges[0].node
  return (
    <Layout locale={locale}>
      <SEO
        title={publicNotice.title}
        description={publicNotice.description}
        hrefLangs={hrefLangs}
      />
      <main role="main">
        <div className="wrapper">
          <section className={styles.publicnotice}>
            <h1>
              <FormattedMessage id="publicNotice" />
            </h1>
            <ul>
              <li>
                <a href="/raw/financial_statement_2018.pdf">
                  {publicNotice.financialStatement2018}
                </a>
              </li>
            </ul>
          </section>
        </div>
      </main>
    </Layout>
  )
}

export default PublicNoticePage

export const query = graphql`
  query($localeLanguage: String!) {
    allContentfulPublicNotice(
      filter: { node_locale: { eq: $localeLanguage } }
    ) {
      edges {
        node {
          title
          subtitle
          financialStatement2018
        }
      }
    }
  }
`
